import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useNavigation } from "../../context/NavigationContext";
import { ColorRing } from "react-loader-spinner";
import axiosInstance from "../../axiosInstance";
const Container = styled.div`
  padding-top: 60px; // Adjust according to the height of your header
  margin: 0px 70px;
  font-size: 18px;
  width: 100%;

  height: 80%;
`;

// margin-left: 300px;
const ButtonNext = styled.button`
  width: 150px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  background-color: #1549e1bf;
  font-weight: bold;

  &:hover {
    background-color: #1d357dbf;
    color: white;
  }
`;

const ButtonPrevious = styled.button`
  width: 150px;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  font-weight: bold;

  &:hover {
    background-color: #bac1d5bf;
    color: white;
  }
`;

const FormCCCD = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
`;

// const nput = styled.input``

const Cmnd = (nextPath) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [backImageURL, setBackImageURL] = useState(null);
  const [file, setFile] = useState(null);
  const [fileBack, setFileBack] = useState(null);

  const { activeIndex, setActiveIndex, menuItems } = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentIndex = menuItems.findIndex(
      (item) => item.path === location.pathname
    );
    setActiveIndex(currentIndex);
  }, [location, navigate, menuItems, setActiveIndex]);

  // useEffect(() => {

  //     console.log(formData);
  // }, []);

  const handlePreviousClick = () => {
    const nextIndex = (activeIndex - 1) % menuItems.length;
    navigate(menuItems[nextIndex].path);
    setActiveIndex(nextIndex);
  };

  const choseFileFront = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageURL(imageUrl);
      setFile(file);
    }
  };

  const choseFileBack = (event) => {
    const file = event.target.files[0];
    if (file) {
      const backImageURL = URL.createObjectURL(file);
      setBackImageURL(backImageURL);
      setFileBack(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file || !fileBack) {
      alert("Vui lòng chọn ảnh trước khi gửi");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    const savedData = localStorage.getItem("formData");

    const bgData = localStorage.getItem("backgroundData");

    if (savedData) {
      const data = JSON.parse(savedData);
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
        // setValue(key, value);
      }
    }
    if (bgData) {
      for (const [key, value] of Object.entries(JSON.parse(bgData))) {
        formData.append(key, value);
        // setValue(key, value);
      }
    }

    formData.append("front", file);
    formData.append("back", fileBack);

    // let config = {
    //     method: 'post',
    //     url: 'http://visgroup.co:5005/add',
    //     // "http://localhost:5000/add",
    //     data: formData
    // };

    try {
      const response = await axiosInstance.post("/add", formData, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //    Accept: 'Application/json'
        // },
        // data: undefined,
       
        transformRequest: (data) => {
          return data;
        },
      });

      console.log("Server Response:", response.data);

      if (response.data["code"] === 0) {
        const nextIndex = (activeIndex + 1) % menuItems.length;
        setActiveIndex(nextIndex);
        navigate(menuItems[nextIndex].path);
      } else {
        alert(`Server returned an error: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      alert(error);
    } finally {
      setIsLoading(false); // Kết thúc loading
    }
  };

  return (
    <Container>
      <h2 style={{ display: "flex", "justify-content": "center" }}>
        HÌNH ẢNH CMND/CCCD NHÂN SỰ
      </h2>
      <p> Nhân viên gửi hình ảnh 2 mặt CMND/CCCD của bản thân. </p>
      <p></p>
      <FormCCCD
        onSubmit={(event) => {
          event.preventDefault(); // Ngăn chặn hành vi mặc định của form
          const confirmation = window.confirm(
            "Thông tin của bạn sẽ được chúng tôi lưu trữ lại. Hãy chắc chắn những thông tin của bạn là đúng!"
          );
          if (confirmation) {
            handleSubmit(event);
          } else {
            // Nếu người dùng chọn 'Cancel', không làm gì cả và ở lại trang web
          }
        }}
      >
        <div className="form-container">
          {/* Trình độ chuyên môn */}
          <div>
            <strong>Mặt trước CMND/CCCD *</strong>{" "}
          </div>
          <section className="form-section">
            <div className="form-row">
              <div className="form-group">
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={choseFileFront}
                />
                {/* {errors.frontCCCD && <div style={{ color: "red" }}>{errors.frontCCCD.message}</div>} */}
                {imageURL && (
                  <img src={imageURL} alt="Front of ID Card" width="200" />
                )}
              </div>
            </div>
          </section>

          {/* Kinh nghiệm làm việc */}
          <div>
            <strong> Mặt sau CMND/CCCD * </strong>{" "}
          </div>
          <section className="form-section">
            <div className="form-row">
              <div className="form-group">
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={choseFileBack}
                />
                {backImageURL && (
                  <img src={backImageURL} alt="Back of ID Card" width="200" />
                )}
              </div>
            </div>
          </section>
          {/* <button type="submit">Submit</button> */}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              "justify-content": "space-between",
              "padding-bottom": "50px",
            }}
          >
            <ButtonPrevious onClick={handlePreviousClick}>
              Bước trước
            </ButtonPrevious>
            <ButtonNext type="submit">Tiếp theo</ButtonNext>
          </div>
        </div>
        {isLoading && (
          <div className="pl-[5%] md:pl-[12%]"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(75 81 99 / 80%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <ColorRing
              height={70}
              width={70}
              color="#4fa94d"
              ariaLabel="loading-indicator"
            />
          </div>
        )}
      </FormCCCD>
    </Container>
  );
};

export default Cmnd;
