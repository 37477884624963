import axios from "axios";

// const api_url = process.env.REACT_APP_API_URL
const api_url = "https://hapi.visgroup.co"
// const api_url = "http://vistest.synology.me:5010/"

const axiosInstance = axios.create({
  baseURL: api_url
});

export default axiosInstance;
