import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/HeaderFotter/SideBar/Sidebar";
import Onboard from "./components/onboarding/1Wca";
import Content from "./components/onboarding/2Thungo";
import AboutVis from "./components/onboarding/3AboutVis";
import InfoNS from "./components/onboarding/4TT";
import Background from "./components/onboarding/5Background";
import Cmnd from "./components/onboarding/6CMND";
import SoTay from "./components/onboarding/7STOnboarding";
import Documents from "./components/onboarding/8Documents";
import Confirmation from "./components/onboarding/9Confirmation.js";

import { Helmet } from "react-helmet";

import { NavigationProvider } from "./context/NavigationContext.js";

function App() {
  return (
    <NavigationProvider>
      <Router>
        <AppContent />
      </Router>
    </NavigationProvider>
  );
}

function AppContent() {
  const location = useLocation();

  const title = "Vis Onboarding";
  const description =
    "Welcome abording! Mong rằng cùng với sự phát triển của VIS Group, chúng ta sẽ có nhiều cơ hội thể hiện chuyên môn cũng như phát huy tốt nhất khả năng làm việc của mình và ngày càng thành công hơn trong sự nghiệp";

  return (
    <div>
      <div className="app-body">
        <div className="side-bar">
          <Sidebar />
        </div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="keywords"
            content="VisGroup, business, development, partnership, vis group, VIS GROUP, vis studio"
          />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://visgroup.co/Img/HomePage/firts_vis.jpg"
          />
        </Helmet>
        <div className="content-area">
          <div className="align-item">
            <Routes>
              <Route path="/" element={<Onboard />} />
              <Route path="/tn" element={<Content />} />
              <Route path="/ab" element={<AboutVis />} />
              <Route path="/info" element={<InfoNS />} />
              <Route path="/background" element={<Background />} />
              <Route path="/cmnd" element={<Cmnd />} />
              <Route path="/sotay" element={<SoTay />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/confirmation" element={<Confirmation />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
