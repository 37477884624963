import React, { createContext, useContext, useState } from 'react';

const menuItems = [
    { path: '/', label: 'Welcome Aboard!' },
    { path: '/tn', label: 'Thư Ngỏ' },
    { path: '/ab', label: 'Về Vis Group' },
    { path: '/info', label: 'Thông tin nhân sự' },
    { path: '/background', label: 'Nền tảng' },
    { path: '/cmnd', label: 'CMND/CCCD' },
    { path: '/sotay', label: 'Sổ tay Onboarding' },
    { path: '/documents', label: 'Tài liệu yêu cầu' },
    { path: '/confirmation', label: 'Xác nhận' },
];

const NavigationContext = createContext(null);

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const value = { activeIndex, setActiveIndex, menuItems };

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};
